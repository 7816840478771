import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import {
  mainBlue,
  mainWhite,
  mainGrey,
  mainOrange,
  screen,
  transHover,
} from "../components/common/variables";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import Collapsible from "react-collapsible";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import LabeledArrow from "../components/labeled-arrow";
import { Waypoint } from "react-waypoint";

const Wrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 73px;
  @media ${screen.xsmall} {
    padding-top: 87px;
    padding-bottom: 320px;
  }
  @media ${screen.small} {
    padding-top: 80px;
  }

  .banner-sm {
    @media ${screen.xsmall} {
      display: none;
    }
  }

  .thermic-introduction {
    color: ${mainBlue};
    display: flex;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 35px;
    position: relative;
    z-index: 2;
    @media ${screen.xsmall} {
      padding-left: 0;
      padding-right: 70px;
      padding-top: 0;
    }
    @media ${screen.small} {
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.large} {
    }
    @media ${screen.xlarge} {
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }

    &__img {
      width: 100%;
      max-width: 600px;
      margin-right: 95px;
      margin-top: 75px;
      display: none;
      @media ${screen.xsmall} {
        margin-right: 40px;
        margin-top: 40px;
        display: block;
      }
      @media ${screen.small} {
        margin-right: 95px;
        margin-top: 75px;
      }
      @media ${screen.xlarge} {
        margin-top: 120px;
      }

      .featured {
      }
    }

    &__text {
      max-width: 503px;
      width: 100%;
      @media ${screen.xsmall} {
        margin-top: 140px;
      }
      @media ${screen.small} {
        margin-top: 0;
      }

      .heading {
        max-width: 420px;
        line-height: 1.25;
      }

      .subheading {
        font-size: 1.17rem;
        font-weight: 300;
        margin: 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.4rem;
        }
        @media ${screen.xlarge} {
          font-size: 1.5rem;
          margin: 25px 0;
        }
      }

      .description {
        p {
          font-size: 1.08rem;
          margin-bottom: 18px;
          @media ${screen.xsmall} {
            font-size: 1.08rem;
            margin-bottom: 22px;
          }
          @media ${screen.xlarge} {
            font-size: 1.25rem;
            margin-bottom: 30px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .hash-link {
        margin-top: 50px;
        cursor: pointer;
        display: flex;
        color: ${mainGrey};
        font-size: 1rem;
        font-weight: 800;
        transition: ${transHover};
        flex-direction: column;
        align-items: flex-start;
        padding-right: 50px;
        line-height: 1.6;
        @media ${screen.xsmall} {
          font-size: 1.05rem;
          align-items: center;
          flex-direction: row;
          padding-right: 0;
          align-items: center;
        }
        @media ${screen.small} {
          margin-bottom: 80px;
        }
        @media ${screen.large} {
          margin-bottom: 0;
        }
        @media ${screen.xlarge} {
          font-size: 1.1rem;
          margin-top: 60px;
        }

        svg {
          width: 72px;
          margin-left: 0;
          margin-top: 15px;
          @media ${screen.xsmall} {
            margin-left: 15px;
            margin-top: 0;
          }

          .right-arrow {
            transition: ${transHover};
          }
        }

        @media ${screen.withCursor} {
          &:hover {
            color: ${mainOrange};
          }
          &:hover .right-arrow {
            stroke: ${mainOrange};
          }
        }
      }
    }
  }

  .thermic-extra {
    color: ${mainBlue};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 50px;
    @media ${screen.xsmall} {
      margin-top: 60px;
      padding-left: 70px;
    }
    @media ${screen.small} {
      padding-left: 50px;
      margin-top: 0;
      flex-direction: row;
    }
    @media ${screen.medium} {
      padding-left: 160px;
      margin-top: 60px;
    }
    @media ${screen.large} {
      margin-top: -60px;
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
    }

    &__carousel {
      width: 100%;
      max-width: 600px;
      padding-top: 35px;
      padding-left: 35px;
      padding-right: 35px;
      order: 1;
      @media ${screen.xsmall} {
        margin-right: 95px;
        padding-top: 50px;
        padding-left: 0;
        padding-right: 0;
      }
      @media ${screen.small} {
        margin-right: 65px;
        order: 0;
        padding-top: 120px;
      }
      @media ${screen.medium} {
        margin-right: 95px;
        max-width: 420px;
      }
      @media ${screen.large} {
        max-width: 600px;
      }
      @media ${screen.xlarge} {
        padding-top: 150px;
      }

      h3 {
        font-size: 1.7rem;
        font-weight: 800;
        line-height: 1.25;
        @media ${screen.xsmall} {
          font-size: 2.3rem;
        }
        @media ${screen.xlarge} {
          font-size: 2.5rem;
        }
      }

      .BrainhubCarousel {
        max-width: 350px;

        &__dots {
          justify-content: flex-start;
          margin-top: 20px;
          li {
          }
        }

        &__dot {
          padding: 10px;
          opacity: 1;

          &--selected {
            &::before {
              background: ${mainWhite} !important;
              border: 2px ${mainBlue} solid;
              height: 10px !important;
              width: 10px !important;
            }
          }

          &::before {
            background: ${mainBlue};
            height: 13px;
            width: 13px;
            border-radius: 0;
          }
        }

        .BrainhubCarouselItem {
          align-items: flex-start;
          .each-carousel {
            p {
              font-size: 1.7rem;
              font-weight: 300;
              line-height: 1.25;
              margin-top: 7px;
              @media ${screen.xsmall} {
                font-size: 2.3rem;
              }
              @media ${screen.xlarge} {
                font-size: 2.5rem;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

    &__accordion {
      background-color: rgba(166, 167, 168, 0.1);
      position: relative;
      width: 100%;
      padding: 35px 35px 35px 35px;
      @media ${screen.xsmall} {
        padding: 48px 70px 68px 0;
      }
      @media ${screen.small} {
        padding: 48px 48px 68px 0;
      }
      @media ${screen.medium} {
        padding: 48px 65px 85px 0;
      }
      @media ${screen.large} {
        padding: 48px 48px 75px 0;
      }
      @media ${screen.xlarge} {
        padding: 48px 0 90px 0;
      }

      .heading {
        font-size: 1.7rem;
        font-weight: 800;
        margin-bottom: 15px;
        @media ${screen.xsmall} {
          font-size: 2.3rem;
        }
        @media ${screen.xlarge} {
          font-size: 2.5rem;
        }
      }

      .each-accord-title {
        max-width: 770px;
        position: relative !important;
        border-bottom: 1px ${mainGrey} solid;
        font-size: 1.05rem;
        padding: 10px 17px 10px 0;
        cursor: pointer;
        @media ${screen.xsmall} {
          font-size: 1.4rem;
          padding: 7px 0;
        }
        @media ${screen.xlarge} {
          font-size: 1.5rem;
          padding: 8px 0;
        }

        &__icon {
          color: ${mainBlue};
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          font-size: 1.3rem;
        }
      }

      .each-accord-body {
        font-size: 1rem;
        padding: 6px 32px 6px 0;
        max-width: 770px;
        @media ${screen.xsmall} {
          font-size: 1.08rem;
          padding: 11px 32px 11px 0;
        }
        @media ${screen.xlarge} {
          font-size: 1.25rem;
        }
      }

      &::before {
        content: "";
        background-color: rgba(166, 167, 168, 0.1);
        position: absolute;
        width: 160px;
        left: -160px;
        top: 0;
        height: 100%;
        @media ${screen.medium} {
          font-size: 1.25rem;
          width: 100px;
          left: -100px;
        }
        @media ${screen.large} {
          width: 160px;
          left: -160px;
        }
      }
    }
  }
`;

const WhatIsThermicLancePage = ({ data }) => {
  const [slideValue, setSlideValue] = useState(0);
  const [isCarouselShow, setCarousel] = useState(false);

  const onSlideChange = (val) => {
    setSlideValue(val);
  };

  const {
    main_heading,
    main_subheading,
    main_description,
    featured_image,
    featured_image_mobile,
    accordion_heading,
    carousel_list,
    accordion_list,
    // blog_list,
    title_tag,
    meta_description,
  } = data.main.data;

  let firstClientX;
  let firstClientY;
  let clientX;
  let clientY;

  // Listener for touch event for preventing to scroll up/down when swiping the carousel.
  useEffect(() => {
    if (isCarouselShow) {
      window.addEventListener("touchstart", touchStart);
      window.addEventListener("touchmove", preventTouch, { passive: false });
      return () => {
        window.removeEventListener("touchstart", touchStart);
        window.removeEventListener("touchmove", preventTouch, {
          passive: false,
        });
      };
    }
  }, [isCarouselShow]);

  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };

  const preventTouch = (e) => {
    const minValue = 5; // threshold

    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  const slides = carousel_list.map((item, i) => (
    <div className="each-carousel" key={i}>
      <p>{item.description}</p>
    </div>
  ));

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="banner-sm">
          {featured_image_mobile.gatsbyImageData && (
            <GatsbyImage
              image={featured_image_mobile.gatsbyImageData}
              alt={featured_image.alt || "Banner"}
            />
          )}
        </div>

        <div className="thermic-introduction">
          <div className="thermic-introduction__img">
            <div className="featured">
              {featured_image.gatsbyImageData && (
                <GatsbyImage
                  image={featured_image.gatsbyImageData}
                  alt={featured_image.alt || "Featured"}
                />
              )}
            </div>
          </div>
          <div className="thermic-introduction__text">
            <PageTitle className="heading">{main_heading}</PageTitle>

            <p className="subheading">{main_subheading}</p>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: main_description.html }}
            />
            <LabeledArrow
              className="hash-link"
              label={`SEE OUR THERMIC LANCE RANGE`}
              url={`/lance-products`}
            />
          </div>
        </div>

        <section className="thermic-extra">
          <Waypoint
            onEnter={() => setCarousel(true)}
            onLeave={() => setCarousel(false)}
          >
            <div className="thermic-extra__carousel">
              <h3>
                A Thermic Lance
                <br />
                can cut through
              </h3>
              {/* carousel */}
              <Carousel
                value={slideValue}
                slides={slides}
                onChange={onSlideChange}
                infinite={true}
                autoPlay={2350}
                animationSpeed={700}
              />
              <Dots
                value={slideValue}
                onChange={onSlideChange}
                number={slides.length}
              />
            </div>
          </Waypoint>

          <div className="thermic-extra__accordion">
            <h2 className="heading">{accordion_heading}</h2>
            {accordion_list.map((item, i) => (
              <Collapsible
                key={i}
                trigger={
                  <h4 className="each-accord-title">
                    {item.heading}
                    <span className="each-accord-title__icon">+</span>
                  </h4>
                }
                triggerWhenOpen={
                  <h4 className="each-accord-title">
                    {item.heading}
                    <span className="each-accord-title__icon">&#8722;</span>
                  </h4>
                }
                transitionTime={200}
              >
                <p className="each-accord-body">{item.description}</p>
              </Collapsible>
            ))}
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default WhatIsThermicLancePage;

export const dataQuery = graphql`
  {
    main: prismicWhatsThermicLancePage {
      data {
        main_heading
        main_subheading
        title_tag
        meta_description
        main_description {
          html
        }
        featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        featured_image_mobile {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        accordion_heading
        accordion_list {
          heading
          description
        }
        carousel_list {
          description
        }
      }
    }
  }
`;
